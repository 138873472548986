input {
  margin-top: 5px;
}

.dispositionmodal {
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
}

.button-content ButtonContent-sc-1aa0hna-2 gPXylH {
  display: none;
}
